/*
 * @File: 面向属性的 CSS
 */

/**
 * 盒模型
 */
 .dpnone { display: none; }
 .dpflex { display: flex; }
 
 .flex1 { flex: 1 }
 
 .widthPct100 { width: 100%; }
 
 /*
  * 间距
  */
 .mg6 { margin: 6px; }
 .mg8 { margin: 8px; }
 .mg12 { margin: 12px; }
 .mg16 { margin: 16px; }
 .mg20 { margin: 20px; }
 
 .pd6 { padding: 6px; }
 .pd8 { padding: 8px; }
 .pd12 { padding: 12px; }
 .pd16 { padding: 16px; }
 .pd20 { padding: 20px; }
 
 .mt6 { margin-top: 6px; }
 .mt8 { margin-top: 8px; }
 .mt12 { margin-top: 12px; }
 .mt16 { margin-top: 16px; }
 .mt20 { margin-top: 20px; }
 
 .mr6 { margin-right: 6px; }
 .mr8 { margin-right: 8px; }
 .mr12 { margin-right: 12px; }
 .mr16 { margin-right: 16px; }
 .mr20 { margin-right: 20px; }
 
 .mb6 { margin-bottom: 6px; }
 .mb8 { margin-bottom: 8px; }
 .mb12 { margin-bottom: 12px; }
 .mb16 { margin-bottom: 16px; }
 .mb20 { margin-bottom: 20px; }
 
 .ml6 { margin-left: 6px; }
 .ml8 { margin-left: 8px; }
 .ml12 { margin-left: 12px; }
 .ml16 { margin-left: 16px; }
 .ml20 { margin-left: 20px; }
 
 .pt6 { padding-top: 6px; }
 .pt8 { padding-top: 8px; }
 .pt12 { padding-top: 12px; }
 .pt16 { padding-top: 16px; }
 .pt20 { padding-top: 20px; }
 
 .pr6 { padding-right: 6px; }
 .pr8 { padding-right: 8px; }
 .pr12 { padding-right: 12px; }
 .pr16 { padding-right: 16px; }
 .pr20 { padding-right: 20px; }
 
 .pb6 { padding-bottom: 6px; }
 .pb8 { padding-bottom: 8px; }
 .pb12 { padding-bottom: 12px; }
 .pb16 { padding-bottom: 16px; }
 .pb20 { padding-bottom: 20px; }
 
 .pl6 { padding-left: 6px; }
 .pl8 { padding-left: 8px; }
 .pl12 { padding-left: 12px; }
 .pl16 { padding-left: 16px; }
 .pl20 { padding-left: 20px; }
  
 /*
  * 字重
  */
 .fw500 { font-weight: 500; }
 .fw600 { font-weight: 600; }
 
 /*
  * 字色
  */
 .fcred6 { color: #f5222d; }
 .fcgrey6 { color: #bfbfbf; }

  
 /*
  * 排版
  */
 .textAlignR { text-align: right; }
 
 /*
  * 鼠标指针
  */
 .cursorDefault { cursor: default; }

.root {
  height: 100%;
}
.main {
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
}
.content {
  flex: 1;
}
.header {
  padding: 0;
  margin-bottom: 12px;
  height: auto;
  background: #f0f2f5;
}

/**
 * @File: 全局共有的 CSS
 */
#root {
  height: 100%;
}

.pageWrapper {
  padding: 20px;
  height: 100%;
  background-color: #fff;
}
.operationText { /* 可点击文字 */
  color: #1890ff;
  cursor: pointer;
}

/* 表单必填提示 */
.form-labelRequired { 
  margin-right: 6px;
  color:#f5222d;
}

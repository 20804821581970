.sliderMenu_root__3ifzh {
  position: fixed;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.sliderMenu_logo__1bgsR {
  margin: 16px;
  height: 32px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
}

.globalHeader_root__moeZe {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 0;
  height: 48px;
  background-color: #fff;
  margin-bottom: 12px;
}
.globalHeader_trigger__Yqh7U {
  padding: 0 12px;
  font-size: 18px;
  line-height: 48px !important;
  cursor: pointer;
  -webkit-transition: 'color 0.3s';
  transition: 'color 0.3s';
}
.globalHeader_breadcrumb__11K-x {
  height: 48px;
  line-height: 43px;
}
.globalHeader_group__3ZVIJ {
  padding-right: 12px;
  height: 100%;
}
.globalHeader_message__34o7i {
  padding: 0 40px;
  height: 100%;
  line-height: 48px;
}
.globalHeader_userBox__dxW4l {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  cursor: pointer;
}
.globalHeader_username__1rJvZ {
  padding-left: 8px;
}
.globalHeader_drawerWrap__2rNmo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  min-height: 170px;
}
.globalHeader_cancel__1HOdh {
  text-align: center;
}

.MainLayout_root__1jgEb {
  height: 100%;
}
.MainLayout_main__svWh8 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.MainLayout_content__3UF2w {
  -webkit-flex: 1 1;
          flex: 1 1;
}
.MainLayout_header__F-O36 {
  padding: 0;
  margin-bottom: 12px;
  height: auto;
  background: #f0f2f5;
}

/**
 * @File: 全局共有的 CSS
 */
#root {
  height: 100%;
}

.pageWrapper {
  padding: 20px;
  height: 100%;
  background-color: #fff;
}
.operationText { /* 可点击文字 */
  color: #1890ff;
  cursor: pointer;
}

/* 表单必填提示 */
.form-labelRequired { 
  margin-right: 6px;
  color:#f5222d;
}

/*
 * @File:表单布局
 */

 .form-col {
  display: -webkit-flex;
  display: flex;
  vertical-align: center
}

 /* 标签 */
/* .form-label {
  display: inline-block;
  line-height: 30px;
  padding-right: 8px;
  width: 100px;
  text-align: right;
} */


/* 必填提示 */
.form-labelRequired { 
  margin-right: 6px;
  color:#f5222d;
}

/* 验证提示 */
.form-hint {
  margin-left: 110px;
  min-height: 20px;
  line-height: 20px;
}

.form-label {
  padding-right: 8px;
  width: 126px;
  line-height: 32px;
  text-align: right;
}

/*
 * @File: 面向属性的 CSS
 */

/**
 * 盒模型
 */
 .dpnone { display: none; }
 .dpflex { display: -webkit-flex; display: flex; }
 
 .flex1 { -webkit-flex: 1 1; flex: 1 1 }
 
 .widthPct100 { width: 100%; }
 
 /*
  * 间距
  */
 .mg6 { margin: 6px; }
 .mg8 { margin: 8px; }
 .mg12 { margin: 12px; }
 .mg16 { margin: 16px; }
 .mg20 { margin: 20px; }
 
 .pd6 { padding: 6px; }
 .pd8 { padding: 8px; }
 .pd12 { padding: 12px; }
 .pd16 { padding: 16px; }
 .pd20 { padding: 20px; }
 
 .mt6 { margin-top: 6px; }
 .mt8 { margin-top: 8px; }
 .mt12 { margin-top: 12px; }
 .mt16 { margin-top: 16px; }
 .mt20 { margin-top: 20px; }
 
 .mr6 { margin-right: 6px; }
 .mr8 { margin-right: 8px; }
 .mr12 { margin-right: 12px; }
 .mr16 { margin-right: 16px; }
 .mr20 { margin-right: 20px; }
 
 .mb6 { margin-bottom: 6px; }
 .mb8 { margin-bottom: 8px; }
 .mb12 { margin-bottom: 12px; }
 .mb16 { margin-bottom: 16px; }
 .mb20 { margin-bottom: 20px; }
 
 .ml6 { margin-left: 6px; }
 .ml8 { margin-left: 8px; }
 .ml12 { margin-left: 12px; }
 .ml16 { margin-left: 16px; }
 .ml20 { margin-left: 20px; }
 
 .pt6 { padding-top: 6px; }
 .pt8 { padding-top: 8px; }
 .pt12 { padding-top: 12px; }
 .pt16 { padding-top: 16px; }
 .pt20 { padding-top: 20px; }
 
 .pr6 { padding-right: 6px; }
 .pr8 { padding-right: 8px; }
 .pr12 { padding-right: 12px; }
 .pr16 { padding-right: 16px; }
 .pr20 { padding-right: 20px; }
 
 .pb6 { padding-bottom: 6px; }
 .pb8 { padding-bottom: 8px; }
 .pb12 { padding-bottom: 12px; }
 .pb16 { padding-bottom: 16px; }
 .pb20 { padding-bottom: 20px; }
 
 .pl6 { padding-left: 6px; }
 .pl8 { padding-left: 8px; }
 .pl12 { padding-left: 12px; }
 .pl16 { padding-left: 16px; }
 .pl20 { padding-left: 20px; }
  
 /*
  * 字重
  */
 .fw500 { font-weight: 500; }
 .fw600 { font-weight: 600; }
 
 /*
  * 字色
  */
 .fcred6 { color: #f5222d; }
 .fcgrey6 { color: #bfbfbf; }

  
 /*
  * 排版
  */
 .textAlignR { text-align: right; }
 
 /*
  * 鼠标指针
  */
 .cursorDefault { cursor: default; }

/**
 * @File: 覆盖样式的 CSS
 */
p {
  margin-bottom: 0;
}
ol,
ul,
dl {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
hr {
  margin: 0;
  height: 1px;
  border: 0;
  background-color: #ccc;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #666;
  font-size: 14px;
}
a:link {
  text-decoration: none;
}
nav::before {
  top: 0 !important;
}


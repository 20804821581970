/**
 * @File: 覆盖样式的 CSS
 */
p {
  margin-bottom: 0;
}
ol,
ul,
dl {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
hr {
  margin: 0;
  height: 1px;
  border: 0;
  background-color: #ccc;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #666;
  font-size: 14px;
}
a:link {
  text-decoration: none;
}
nav::before {
  top: 0 !important;
}

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  height: 48px;
  background-color: #fff;
  margin-bottom: 12px;
}
.trigger {
  padding: 0 12px;
  font-size: 18px;
  line-height: 48px !important;
  cursor: pointer;
  transition: 'color 0.3s';
}
.breadcrumb {
  height: 48px;
  line-height: 43px;
}
.group {
  padding-right: 12px;
  height: 100%;
}
.message {
  padding: 0 40px;
  height: 100%;
  line-height: 48px;
}
.userBox {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}
.username {
  padding-left: 8px;
}
.drawerWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 170px;
}
.cancel {
  text-align: center;
}
